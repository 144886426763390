import React from 'react';

import { Spacing, Container, TabsSide } from '@matchbox-mobile/kenra-storybook';

export default function FAQ({ items }) {
  let { sections } = items;
  return (
    <Spacing>
      <Container useLargeWidth>
        <TabsSide
          items={sections.map(section => ({
            title: section.name,
            accordionItems: Array.isArray(section.qa)
              ? section.qa.map(qa => ({
                  title: qa.q,
                  text: qa.a,
                }))
              : [],
          }))}
        />
      </Container>
    </Spacing>
  );
}
