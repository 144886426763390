import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import FAQ from 'components/FAQ';

export default function FAQPage(props) {
  let {
    data: {
      contentfulFaq: { items },
    },
  } = props;

  return (
    <Layout>
      <FAQ items={items} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulFaq {
      items {
        sections {
          qa {
            a
            q
          }
          name
        }
      }
    }
  }
`;
